<template>
  <div>
    <div class="pb-3">
      <b-button v-has-permission="'QUOTE_CREATE_PERMISSION'" @click="add()" variant="dark">
        <b-icon-plus-circle></b-icon-plus-circle>
        {{ $t('LIST_ADD_LABEL', {catalog: $t('QUOTE')}) | capitalize}}
      </b-button>

      <b-button class="ml-1" @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL') | capitalize}}</span>
      </b-button>

      <!-- clear button -->
      <b-button class="ml-1" @click="clear" variant="dark">
        <b-icon-x></b-icon-x>
        <span class="ml-1">{{ $t('LIST_FILTER_CLEAR_LABEL') | capitalize}}</span>
      </b-button>
      <!-- clear button -->
    </div>

    <div class="mb-1">
      <!-- List page filter -->
      <quote-list-filter :only-active="onlyActive" class="d-inline-block w-75" @search="setFilter" v-model="filter"></quote-list-filter>
      <!-- List page filter -->

      <!-- Page size -->
      <div class="d-inline-block pl-1 w-25">
        <page-size v-model="size"></page-size>
      </div>
      <!-- Page size -->
    </div>

    <b-table
        id="quotes-table"
        ref="quotesTable"
        @row-selected="handleSelection"
        v-model="items"
        striped
        hover
        bordered
        small
        table-variant="dark"
        selectable
        :select-mode="selectMode"
        :filter="filter"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :items="listAll">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('QUOTES')})  | capitalize}}</strong>
        </div>
      </template>

      <template #head(multiselect)="">
        <input :value="true" type="checkbox" v-model="allSelected" @click="selectAll"/>
      </template>

      <template #cell(multiselect)="data">
        <input :checked="isChecked(data)" @click="toggleRow(data)" type="checkbox"/>
      </template>

      <template #head(number)="">
        <span>{{ $t('QUOTE_NUMBER_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(number)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.number }}</span>
        </div>
      </template>

      <template #head(date)="">
        <span>{{ $t('QUOTE_DATE_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(date)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.date | date}}</span>
        </div>
      </template>

      <template #head(customer)="">
        <span>{{ $t('QUOTE_CUSTOMER_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(customer)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.date }}</span>
        </div>
      </template>

      <template #head(projectName)="">
        <span>{{ $t('QUOTE_PROJECT_NAME_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(projectName)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.projectName }}</span>
        </div>
      </template>

      <template #head(currency)="">
        <span>{{ $t('QUOTE_CURRENCY_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(currency)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.currency }}</span>
        </div>
      </template>

      <template #head(exchangeRate)="">
        <span>{{ $t('QUOTE_EXCHANGE_RATE_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(exchangeRate)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.exchangeRate | currency}}</span>
        </div>
      </template>

      <template #head(subtotal)="">
        <span>{{ $t('QUOTE_SUBTOTAL_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(subtotal)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.subtotal | currency}}</span>
        </div>
      </template>

      <template #head(iva)="">
        <span>{{ $t('QUOTE_IVA_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(iva)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.iva | currency}}</span>
        </div>
      </template>

      <template #head(total)="">
        <span>{{ $t('QUOTE_TOTAL_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(total)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.total | currency}}</span>
        </div>
      </template>

      <template #head(enabled)="">
        <span>{{ $t('LIST_ENABLED_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(options)="">
        <span>{{ $t('LIST_OPTIONS_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown size="sm" id="dropdown-1" right>
            <b-dropdown-item v-has-permission="'QUOTE_UPDATE_PERMISSION'" @click="edit(data.item.id)">
              {{ $t('LIST_EDIT_LABEL')  | capitalize}}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'QUOTE_DELETE_PERMISSION'"
                             @click="remove(data.item.id, data.item.name)">{{ $t('LIST_DELETE_LABEL')  | capitalize}}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'QUOTE_DISABLE_PERMISSION'"
                             @click="disable(data.item.id, data.item.name)" v-if="data.item.enabled">
              {{ $t('LIST_DISABLE_LABEL')  | capitalize}}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'QUOTE_ENABLE_PERMISSION'"
                             @click="enable(data.item.id, data.item.name)" v-else>{{ $t('LIST_ENABLE_LABEL')  | capitalize}}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>

    <b-container fluid>
      <b-row>
        <b-col>
          <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="size"
              pills
              aria-controls="quotes-table"
          ></b-pagination>
        </b-col>
        <b-col class="text-right">
          {{ (pageTotals.size ? $t('GRID_ROW_TOTAL', pageTotals) : $t('GRID_ALL_ROWS_TOTAL')) | capitalize }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {DateFilter, NumberFilter, Table} from "@/mixins";
import Quotes from "@/quotes";
import eventBus from "@/events";
import Constants from "@/constants";
import QuoteListFilter from "@/quotes/QuoteListFilter";
import PageSize from "@/components/PageSize";

export default {
  name: "QuoteList",
  components: {PageSize, QuoteListFilter},
  props: ['selectMode', 'currentlySelected', 'onlyActive'],
  mixins: [Table, NumberFilter, DateFilter],
  mounted() {
    eventBus.$on(Constants.EVENT_ON_PROFILE_LIST, (totalElements) => {
      this.totalRows = totalElements;
      if (this.currentlySelected) {
        this.originals = this.currentlySelected;
      }
    });
  },
  methods: {},
  computed: {
    fields() {
      return [
        {key: 'multiselect', sortable: false, tdClass: 'selectTd'},
        {key: 'number', sortable: true, tdClass: 'customTd'},
        {key: 'date', sortable: true, tdClass: 'customTd'},
        {key: 'customer', sortable: true, tdClass: 'nameTd'},
        {key: 'projectName', sortable: true, tdClass: 'nameTd'},
        {key: 'currency', sortable: true, tdClass: 'customTd'},
        {key: 'exchangeRate', sortable: true, tdClass: 'customTd'},
        {key: 'subtotal', sortable: true, tdClass: 'customTd'},
        {key: 'iva', sortable: true, tdClass: 'customTd'},
        {key: 'total', sortable: true, tdClass: 'customTd'},
        {key: 'enabled', sortable: true, tdClass: 'customTd'},
        {key: 'options', sortable: false, tdClass: 'optionsTd'}];
    },
    tableRef() {
      return this.$refs.quotesTable;
    },
    controller() {
      return Quotes;
    }
  },
  data() {
    return {
      registerPage: 'QUOTE_REGISTER_PAGE_TITLE',
      editPage: 'QUOTE_EDIT_PAGE_TITLE',
      selectedCount: 0,
      allSelected: false,
      originals: []
    }
  }
}
</script>

<style>
.selectTd {
  width: 15px !important;
}
.nameTd {
  width: 75% !important;
}
</style>
